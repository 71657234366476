


















































import {Component, Vue, Watch} from 'vue-property-decorator';
import SettingsItem from '@/components/SettingsItem.vue';
import DaySettings from '@/components/DaySettings.vue';
import Selector from '@/components/Selector.vue';
import {settingsStore} from '@/store/modules/settings';

@Component({
  name: 'schedules',
  components: {Selector, DaySettings, SettingsItem}
})
export default class Schedules extends Vue {

  dirty: boolean = false;

  excludeFromTime: string = settingsStore.excludeFromTime
  excludeToTime: string = settingsStore.excludeToTime

  get workingDays() {
    return settingsStore.businessSettings.workingDays || [];
  }

  get alwaysOpen() {
    return !!settingsStore.businessSettings.alwaysOpen
  }

  set alwaysOpen(value: boolean) {
    settingsStore.setBusinessAlwaysOpen(value)
  }

  @Watch('workingDays', {deep: true})
  onChange(after, before) {
    if (!before) {
      return;
    }
    settingsStore.changeWorkingDays(after);
  }

  async onAlwaysOpenChanged(value: boolean) {
    await settingsStore.alwaysOpenChanged(value)
  }
}
